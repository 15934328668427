import React, { useState } from 'react';
import RequestHeadersView from '../../../components/RoutesView/RequestHeadersView';
import JsonSchemaViewer from '../../../components/JsonSchemaViewer/JsonSchemaViewer';
import RequestParamsView from '../../../components/RoutesView/RequestParamsView';
import { ChevronDown } from 'react-feather';
import { Collapse } from 'react-collapse';

export default function RequestTab({ route, models, routeHeaders, variables }) {
    const [isOpened, setIsOpened] = useState(true);
    return (
        <div className="mt-4">
            <RequestHeadersView items={routeHeaders} variables={variables} />
            {(route.method === 'PUT' ||
                route.method === 'PATCH' ||
                route.method === 'POST') && (
                <div className="mt-3">
                    <div
                        onClick={() => setIsOpened(!isOpened)}
                        className="d-flex justify-content-between align-items-center ps-3 pe-2 cursor-pointer"
                        style={{
                            height: '47px',
                            borderBottom: '2px solid #edeff3',
                        }}
                    >
                        <h6 className="text">Body</h6>
                        <ChevronDown
                            size={16}
                            color="#001A37"
                            style={{
                                transform: isOpened && 'rotate(180deg)',
                            }}
                        />
                    </div>
                    <Collapse isOpened={isOpened}>
                        <div className="pt-4">
                            <JsonSchemaViewer
                                models={models}
                                schema={route?.schema}
                                schemaType="request"
                            />
                        </div>
                    </Collapse>
                </div>
            )}
            <RequestParamsView
                items={route.path_parameters}
                title="Path parameters"
            />
            <RequestParamsView
                items={route.query_parameters}
                title="Query parameters"
            />
        </div>
    );
}
