import { combineReducers } from 'redux';
import { pageReducer } from './slices/page';
import { routeReducer } from './slices/route';
import { documentationReducer } from './slices/documentation';

export const rootReducer = combineReducers({
    page: pageReducer,
    route: routeReducer,
    documentation: documentationReducer,
});
