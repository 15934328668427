import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import LocalLoader from '../../../components/LocalLoader';
import PageNotFound from '../../../components/PageNotFound';
import { useAction } from '../../../hooks/useAction';
import { documentationSelectors, pageSelectors } from '../../../redux/selectors';

const PageView = () => {
    const { getPage } = useAction();
    const { id } = useParams();

    const api = useSelector(documentationSelectors.getApi)
    const isApiInformationFetching = useSelector(documentationSelectors.getIsApiInformationFetching)
    const page = useSelector(pageSelectors.getPage)
    const isPageFetching = useSelector(pageSelectors.getIsPageFetching)

    const token = localStorage.getItem('access_token');

    useEffect(() => {
        if (!isApiInformationFetching) {
            getPage({ id: api.id, pageId: id, token });
        }
    }, [api, getPage, id, isApiInformationFetching, token]);

    return (
        <>
            <Helmet>
                <title>
                    {`${page?.title} - ${api.name} - ${api.project_name}`} -
                    CDDocs
                </title>
            </Helmet>
            <LocalLoader loading={isPageFetching} className="mt-5">
                {!!page ? (
                    <div className="row">
                        <div className="col-12 col-xl-10">
                            <div className="row">
                                <div className="col col-lg-12">
                                    <h3 className="title-h4 word-break fw-bold">
                                        {page.title}
                                    </h3>
                                    {page.description && (
                                        <div className="mb-3">
                                            <div className="ck page-description-paragraph">
                                                <Markdown className="mb-4 ck">
                                                    {page.description}
                                                </Markdown>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <PageNotFound />
                )}
            </LocalLoader>
        </>
    );
};

export default PageView;
