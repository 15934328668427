import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomSelect from '../CustomSelect';
import { documentationSelectors, routeSelectors } from '../../redux/selectors';
import { documentationAction } from '../../redux/slices';

const selectStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        color: '#fff',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '400',
    }),

    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? '#f6f7f9'
            : state.isSelected
            ? '#edeff3'
            : 'white',
        color: '#001a37',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    }),

    control: (provided, state) => ({
        ...provided,
        width: '100%',
        display: 'flex',
        borderColor: state.isFocused ? '' : '',
        boxShadow: state.isFocused ? '' : '',
        color: '#000',
        borderRadius: '4px',
        border: 'none',
        height: '40px',
        backgroundColor: '#f6f7f9',
        cursor: 'pointer',
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#000',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        transition: 'transform 100ms linear',
    }),
};

const EnvironmentSelect = () => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState('');
    const environments = useSelector(documentationSelectors.getEnvironments);
    const isRoutesFetching = useSelector(routeSelectors.getIsRoutesFetching);

    useEffect(() => {
        const environmentsOptions = environments?.map((item) => ({
            value: item.name.toLowerCase(),
            label: item.name,
            item,
        }));
        dispatch(
            documentationAction.setSelectedEnvironment(
                environmentsOptions[0]?.item,
            ),
        );
        setSelectedValue(environmentsOptions[0]?.value);
    }, [environments, dispatch]);

    const onSelectChange = (option) => {
        const selectedValue = option.value;
        const selected = environments.find(
            (el) => el.name.toLowerCase() === option.value.toLowerCase(),
        );
        dispatch(documentationAction.setSelectedEnvironment(selected));
        setSelectedValue(selectedValue);
    };

    const environmentsOptions = environments?.map((item) => ({
        value: item.name.toLowerCase(),
        label: item.name,
        item,
    }));

    return (
        <div style={{ width: '200px' }}>
            {environmentsOptions.length > 0 && (
                <CustomSelect
                    name="env"
                    options={environmentsOptions}
                    onChange={onSelectChange}
                    value={selectedValue}
                    menuPortalTarget={'null'}
                    customStyles={selectStyles}
                    isLoading={isRoutesFetching}
                />
            )}
        </div>
    );
};

export default EnvironmentSelect;
