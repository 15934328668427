import HeaderLogo from './HeaderLogo/HeaderLogo';
import EnvironmentSelect from '../EnvironmentSelect';

const Header = ({ className, api }) => {
    return (
        <div className={className}>
            <nav className="main-container d-flex justify-content-between align-items-center px-3 py-2">
                <div className="header-logo-wrapper d-flex align-items-center">
                    <HeaderLogo
                        src={
                            api.project_logo_thumb_url || api.project_avatar_url
                        }
                    />
                    <span className="fw-bold ms-3 d-block">
                        {api.project_name}
                    </span>
                </div>
                <EnvironmentSelect />
            </nav>
        </div>
    );
};

export default Header;
