import { NavLink } from 'react-router-dom';
import iconPage from '../../../assets/img/page.svg';

const PagesItem = ({ page }) => {
    return (
        <li key={page.id} className="nav-item sidebar-link-item mx-2">
            <NavLink
                className={
                    'nav-link sidebar-link sidebar-link-route word-break'
                }
                activeClassName="sidebar-link-active nav-link sidebar-link sidebar-link-route word-break"
                exact
                to={`/page/${page.id}`}
            >
                <img
                    src={iconPage}
                    alt="page"
                    style={{ width: '20px', height: '14px' }}
                />
                <span className="me-1">{page.title}</span>
            </NavLink>
        </li>
    );
};

export default PagesItem;
