import React, { Fragment, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkbox';
import { ChevronDown } from 'react-feather';

const defaultStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        color: '#001a37',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '400',
        backgroundColor: 'white',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? '#f6f7f9'
            : state.isSelected
            ? '#f6f7f9;'
            : 'white',
        color: '#001a37',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        display: 'flex',
        alignItems: 'center',
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        display: 'flex',
        borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : '',
        color: '#001a37',
        borderRadius: '4px',

        '&:hover': {
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        },
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

const Menu = (props) => {
    return (
        <Fragment>
            <components.Menu {...props}>
                <div>{props.children}</div>
            </components.Menu>
        </Fragment>
    );
};

const Option = (props) => {
    return (
        <Fragment>
            <components.Option {...props}>{props.children}</components.Option>
        </Fragment>
    );
};

const FormErrorText = ({ text }) => {
    if (text) {
        return (
            <div className="invalid-feedback" style={{ display: 'block' }}>
                {text}
            </div>
        );
    }
    return null;
};
const CustomSelect = ({
    options,
    defaultOption,
    onChange,
    className,
    value,
    customStyles,
    fieldError,
    isMulti,
    isDisabled,
    menuPortalTarget,
    isLoading,
}) => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (fieldError) {
            showError();
        }
        if (!fieldError) {
            clearError();
        }
    }, [fieldError]);

    const showError = () => {
        setIsError(true);
    };

    const clearError = () => {
        // Remove all error styles
        setIsError(false);
    };

    const errorText = fieldError ? fieldError[0] : '';
    // const styles = customStyles ? customStyles(fieldError) : defaultStyles(fieldError);

    const styles = {
        ...defaultStyles,
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (provided, state) =>
            isError
                ? {
                      ...provided,
                      border: `2px solid #dc3545`,
                      borderColor: '#dc3545 !important',
                      width: '100%',
                      display: 'flex',
                      color: '#001a37',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      height: '44px',
                  }
                : {
                      ...provided,
                      width: '100%',
                      display: 'flex',
                      borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
                      boxShadow: state.isFocused
                          ? '0 0 0 0.25rem rgb(13 110 253 / 25%)'
                          : '',
                      color: '#001a37',
                      borderRadius: '4px',
                      '&:hover': {
                          // Overwrittes the different states of border
                          borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
                      },
                  },
        ...customStyles,
    };

    const multiStyles = {
        ...defaultStyles,
        control: (provided, state) => ({
            ...provided,
            borderColor: '#F6F7F9',
            width: '100%',
            display: 'flex',
            boxShadow: 'none',
            color: '#001a37',
            borderRadius: '8px 8px 0px 0px',
            margin: 0,
            overflow: 'hidden',
            '&:hover': {
                // Overwrittes the different states of border
                borderColor: '#F6F7F9',
            },
        }),
        dropdownButton: (provided) => ({
            ...provided,
            width: '100%',
            color: '#001a37',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: '400',
            backgroundColor: 'white',
            borderColor: '#edeff3',
            borderRadius: '8px',
            borderStyle: 'solid',
            borderWidth: '2px',
            height: '44px',
            boxShadow: '',
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '0 0 8px 8px',
            boxShadow: 'none',
            overflow: 'hidden',
            border: '1px solid #F6F7F9',
        }),
    };

    const getValue = isMulti
        ? options && options.filter((obj) => value.includes(obj.value))
        : options.filter((option) => option.value === value);

    const props = {
        className,
        options,
        onChange,
        value: getValue,
        onFocus: clearError,
        classNamePrefix: 'select-main',
        isDisabled,
        isLoading,
    };

    return (
        <div>
            {!isMulti ? (
                <Select
                    {...props}
 
                    components={{ Menu, Option }}
                    styles={styles}
                    defaultOption={defaultOption}
                    menuPortalTarget={
                        menuPortalTarget === 'null' ? null : document.body
                    }
                />
            ) : (
                <ReactMultiSelectCheckboxes
                    {...props}
                    styles={multiStyles}
                    components={{
                        DropdownIndicator: () => null,
                        DropdownButtonIcon: () => (
                            <ChevronDown color="#001A37" size={20} />
                        ),
                    }}
                />
            )}
            {isError && <FormErrorText text={errorText} />}
        </div>
    );
};
export default CustomSelect;
