import axios from 'axios';
import { toast } from 'react-toastify';

const baseURL = process.env.REACT_APP_API_URL;

const http = axios.create({
    baseURL: baseURL,
});

http.defaults.headers = {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
};

const responseHandler = (response) => {
    // Handle responses
    return response;
};

const errorHandler = (error) => {
    switch (error.response.status) {
        case 401:
            window.location.pathname = '/';
            break;
        case 403:
            window.location.pathname = '/';
            localStorage.removeItem('access_token');
            break;
        case 404:
            toast.error(
                'The data you are trying to reach is temporary unavailable',
            );
            break;
        case 500:
            toast.error(error.response.data.errors);
            break;
        default:
            break;
    }
    return Promise.reject({ ...error });
};

http.interceptors.request.use((request) => {
    // Add your logic here
    return request;
});

http.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
);

export default http;
