export function countRouteMethod(method, customStyle = {}) {
    let style = {
        marginRight: '4px',
        fontSize: '10px',
        minWidth: '31px',
        height: '14px',
        textAlign: 'center',
        fontWeight: '600',
        whiteSpace: 'nowrap',
    };
    style = Object.assign({}, style, customStyle);

    switch (method) {
        case 'GET':
            style = { ...style, color: 'rgb(34, 142, 55)' };
            return <span style={style}>GET</span>;
        case 'POST':
            style = { ...style, color: 'rgb(254, 166, 11)' };
            return <span style={style}>POST</span>;
        case 'PUT':
            style = { ...style, color: 'rgb(17, 97, 229)' };
            return <span style={style}>PUT</span>;
        case 'PATCH':
            style = { ...style, color: '#909090' };
            return <span style={style}>PATCH</span>;
        case 'DELETE':
            style = { ...style, color: 'rgb(228, 0, 17)' };
            return <span style={style}>DEL</span>;
        default:
            style = { ...style, color: '#fed42f' };
            return <span style={style}>OTHER</span>;
    }
}

export function defineHost() {
    const host = window.location.host;

    switch (host) {
        case 'cddocs.com':
            window.location.href = 'https://cdprojects.com/';
            break;
        case 'test-cddocs.com':
            window.location.href = 'https://cdprojects.com/';
            break;
        case 'localhost:3001':
            window.location.href = 'https://cdprojects.com/';
            break;
        default:
            break;
    }
}

export function replaceEnvVariable(initialString, envVariablesArray) {
    if (!envVariablesArray) return initialString;

    if (typeof initialString !== 'string') {
        throw new TypeError(
            `Wrong data type at first parameter. 'String' expected, instead got '${typeof initialString}'`,
        );
    }

    if (!Array.isArray(envVariablesArray)) {
        throw new TypeError(
            `Wrong data type at second parameter. 'Array' expected, instead got '${typeof envVariablesArray}'`,
        );
    }

    const regExp = /\{\{.*?\}\}/g;
    const matchesArray = initialString.match(regExp);

    if (!matchesArray) return initialString;

    const newString = matchesArray.reduce((acc, matchElem) => {
        envVariablesArray.forEach((envVariable) => {
            if (
                envVariable.name === matchElem.slice(2, -2) &&
                envVariable.is_active === 1
            ) {
                acc = acc.replace(matchElem, envVariable.value);
            }
        });
        return acc;
    }, initialString);

    return newString;
}

export const createShallowCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
};

export const generateId = () => {
    return Math.random().toString(16).slice(2);
};

export const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);
