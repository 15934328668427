import { Component } from 'react';
import AppErrorFallback from '../AppErrorFallback/AppErrorFallback';

export default class ErrorBoundary extends Component {
    state = { hasError: false, error: null };
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    render() {
        if (this.state?.hasError) {
            return <AppErrorFallback />;
        }
        return this.props.children;
    }
}
