import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import DocumentationPage from './DocumentationPage';
import DocumentationLoginForm from './DocumentationLoginForm';
import GlobalLoader from '../../../components/GlobalLoader';
import PageNotFound from '../../PageNotFound';
import NoAccess from '../../../components/NoAccess';
import {
    documentationSelectors,
    pageSelectors,
    routeSelectors,
} from '../../../redux/selectors';
import { useAction } from '../../../hooks/useAction';

const DocumentationPageLayout = () => {
    const {
        getRoutes,
        getResources,
        // getResponses,
        getPages,
        getRoutesGroups,
        getEnvironments,
        getApiDocumentation,
    } = useAction();
    const pages = useSelector(pageSelectors.getPages);
    const isPagesFetching = useSelector(pageSelectors.getIsPagesFetching);
    const routes = useSelector(routeSelectors.getRoutes);
    const isRoutesFetching = useSelector(routeSelectors.getIsRoutesFetching);
    const routesGroups = useSelector(routeSelectors.getRoutesGroups);
    const apiData = useSelector(documentationSelectors.getApi);
    const showGlobalLoader = useSelector(
        documentationSelectors.getShowGlobalLoader,
    );
    const isApiInformationFetching = useSelector(
        documentationSelectors.getIsApiInformationFetching,
    );
    const [api, setApi] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorStatus, setErrorStatus] = useState(null);
    const token = localStorage.getItem('access_token');

    const toggleIsAuthenticated = useCallback(
        () => setIsAuthenticated(!isAuthenticated),
        [isAuthenticated],
    );

    const onGetRoutesError = useCallback(() => {
        toast.error('Something went wrong, please try again later.');
        localStorage.removeItem('access_token');
        toggleIsAuthenticated();
    }, [toggleIsAuthenticated]);

    const getData = useCallback(async () => {
        if ((token || api.access === 'PUBLIC') && api.id) {
            await getResources({ apiId: api.id, token, onGetRoutesError });
            // await getResponses({ apiId: api.id, token, onGetRoutesError });
            await getRoutes({ apiId: api.id, token, onGetRoutesError });
            await getPages({ apiId: api.id, token, onGetRoutesError });
            await getRoutesGroups({ apiId: api.id, token, onGetRoutesError });
            await getEnvironments({ apiId: api.id, token, onGetRoutesError });
        }
    }, [
        api.access,
        api.id,
        getEnvironments,
        getPages,
        getResources,
        // getResponses,
        getRoutes,
        getRoutesGroups,
        onGetRoutesError,
        token,
    ]);

    const onSuccess = useCallback(
        (response) => {
            setApi(response.data.data);
            getData();
        },
        [getData],
    );

    useEffect(() => {
        const publicUrl = window.location.host.split('.')[0];
        if (token) {
            setIsAuthenticated(true);
        }
        getApiDocumentation({ publicUrl, onSuccess, onError });
    }, [getApiDocumentation, onSuccess, token]);

    const onError = (error) => {
        setErrorStatus(error);
        console.log(error);
    };

    if (errorStatus === 404) {
        return <PageNotFound />;
    }

    if (apiData.access === 'PRIVATE' || apiData.access === null) {
        return <NoAccess />;
    }

    if (!isAuthenticated && apiData.access === 'PROTECTED') {
        return (
            <>
                <DocumentationLoginForm
                    toggleIsAuthenticated={toggleIsAuthenticated}
                    getData={getData}
                />
                <GlobalLoader
                    isLoading={showGlobalLoader}
                    bgColor="#ccd3db82"
                />
            </>
        );
    }

    return (
        <>
            {(isAuthenticated || apiData.access === 'PUBLIC') && (
                <>
                    <DocumentationPage
                        pages={pages}
                        isPagesFetching={isPagesFetching}
                        routes={routes}
                        isRoutesFetching={isRoutesFetching}
                        groups={routesGroups}
                        api={apiData}
                    />
                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                        icon={false}
                    />
                </>
            )}
            <GlobalLoader isLoading={isApiInformationFetching} />
        </>
    );
};

export default DocumentationPageLayout;
