import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from '../../../utils/http';

export const getApiDocumentation = createAsyncThunk(
    'documentation/getApiInformation',
    async ({ publicUrl, onSuccess, onError }, thunkAPI) => {
        try {
            const response = await http.get(`/api/v1/api/public/${publicUrl}`);
            onSuccess(response);

            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const getEnvironments = createAsyncThunk(
    'documentation/getEnvironments',
    async ({ apiId, token, onError }, thunkAPI) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/environments`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );

            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const loginDocumentation = createAsyncThunk(
    'documentation/login',
    async ({ data, onSuccess, onError }, thunkAPI) => {
        try {
            const response = await http.post(
                `/api/v1/documentation/login`,
                data,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

const initialState = {
    api: {},
    environments: [],
    selectedEnvironment: {},
    isApiInformationFetching: false,
    showGlobalLoader: false,
};

export const slice = createSlice({
    name: 'documentation',
    initialState,
    reducers: {
        setSelectedEnvironment(state, action) {
            state.selectedEnvironment = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginDocumentation.pending, (state) => {
                state.showGlobalLoader = true;
            })
            .addCase(loginDocumentation.fulfilled, (state) => {
                state.showGlobalLoader = false;
            })
            .addCase(loginDocumentation.rejected, (state) => {
                state.showGlobalLoader = false;
            })
            .addCase(getApiDocumentation.pending, (state) => {
                state.isApiInformationFetching = true;
            })
            .addCase(getApiDocumentation.fulfilled, (state, action) => {
                state.api = action.payload;
                state.isApiInformationFetching = false;
            })
            .addCase(getApiDocumentation.rejected, (state) => {
                state.isApiInformationFetching = false;
            })
            .addCase(getEnvironments.fulfilled, (state, action) => {
                state.environments = action.payload;
            });
    },
});

export const { actions: documentationAction, reducer: documentationReducer } =
    slice;
