import React, { useState } from 'react';
import { useLocation, Route, NavLink, Switch } from 'react-router-dom';
import Header from '../../../components/Header';
import {
    LeftSideBarHeader,
    LeftSideBar,
} from '../../../components/LeftSideBar';
import MainContent from '../../../components/MainContent';
import RouteView from '../Routes/RouteView';
import RoutesList from '../Routes/RoutesList';
import InputSearch from '../../../components/InputSearch';
import RouteNotFound from '../../../components/RouteNotFound';
import Markdown from 'markdown-to-jsx';
import { Helmet } from 'react-helmet';
import PagesList from '../Pages/PagesList';
import PageView from '../Pages/PageView';
import LocalLoader from '../../../components/LocalLoader';

const searchInputStyle = {
    width: '100%',
    padding: '0.5rem 0.8rem 0.5rem 2rem',
    borderRadius: '4px',
    maxHeight: '40px',
};

const DocumentationPage = ({
    pages,
    isPagesFetching,
    routes,
    isRoutesFetching,
    groups,
    api,
}) => {
    const location = useLocation();
    const [inputSearch, setInputSearch] = useState('');

    const apiRoutesPath = location.params?.id ? '/route/:id' : '/';
    const media = window.matchMedia('(min-width: 1200px)');

    const routesList = routes?.filter((route) => {
        const name = route.name || '';
        const url = route.url || '';
        return (
            url.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1 ||
            name.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1
        );
    });

    const pagesList = pages?.filter((page) => {
        const name = page.title || '';
        return name?.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1;
    });

    const handleChange = (e) => {
        setInputSearch(e.target.value);
    };

    return (
        <>
            <Helmet>
                <title>{`${api?.name} - ${api?.project_name} - CDDocs`}</title>
            </Helmet>
            <div className="master-container">
                <Header
                    className="bg-white header-wrapper header-wrapper-shadow"
                    api={api}
                />
                <div className="main-container">
                    <div className="d-flex justify-content-start main-layout">
                        <nav className="h-100 d-flex flex-column">
                            <LeftSideBar
                                initialWidth={media.matches ? 380 : 280}
                                minWidth={240}
                            >
                                <LeftSideBarHeader className="flex-column align-items-start">
                                    <div className="d-flex align-items-center mb-4">
                                        <img
                                            className="d-block me-3"
                                            src={api.avatar_url}
                                            alt="logo"
                                            width="32"
                                            height="32"
                                        />
                                        <div>
                                            <p className="text-semi-small-normal fw-bold">
                                                {api.name}
                                            </p>
                                            <p className="text-semi-small-normal opacity-75">
                                                Documentation
                                            </p>
                                        </div>
                                    </div>
                                    <InputSearch
                                        id="input-search"
                                        type="text"
                                        placeHolder="Search..."
                                        inputStyle={searchInputStyle}
                                        labelStyle={{ width: '100%' }}
                                        className="form-control"
                                        value={inputSearch}
                                        onChange={handleChange}
                                    />
                                </LeftSideBarHeader>
                                <div className="routes-nav-panel d-flex flex-column m-0">
                                    <NavLink
                                        className={
                                            'nav-link sidebar-link sidebar-link-route word-break'
                                        }
                                        activeClassName="sidebar-link-active nav-link sidebar-link sidebar-link-route word-break"
                                        exact
                                        to="/"
                                    >
                                        Intro
                                    </NavLink>
                                </div>
                                <LocalLoader
                                    loading={
                                        isPagesFetching || isRoutesFetching
                                    }
                                >
                                    <PagesList pages={pagesList} api={api} />
                                    <RoutesList
                                        pages={pagesList}
                                        routes={routesList}
                                        isRoutesFetching={isRoutesFetching}
                                        groups={groups}
                                        inputSearch={inputSearch}
                                        api={api}
                                    />
                                </LocalLoader>
                            </LeftSideBar>
                        </nav>
                        <MainContent>
                            <div className="main-container m-5">
                                <Switch>
                                    <Route exact path={apiRoutesPath}>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="fs-5 fw-bold mb-3 word-break">
                                                    {api.description_title ||
                                                        'CD Projects API Reference'}
                                                </p>
                                                {api.description_text ? (
                                                    <Markdown className="page-description-paragraph">
                                                        {api.description_text}
                                                    </Markdown>
                                                ) : (
                                                    'The API reference documentation provides detailed information for each of the classes and methods in the CD Projects platform. You can find some in the left bar'
                                                )}
                                            </div>
                                        </div>
                                    </Route>
                                    <Route
                                        exact
                                        path={`/route/:id`}
                                        component={RouteView}
                                    />
                                    <Route
                                        exact
                                        path={`/page/:id`}
                                        component={PageView}
                                    />
                                    <Route>
                                        <RouteNotFound />
                                    </Route>
                                </Switch>
                            </div>
                        </MainContent>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DocumentationPage;
