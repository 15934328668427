import React from 'react';
import ResponseExampleView from '../../../components/RoutesView/ResponseExampleView';

export default function ResponsesTab({ route, resources, responses }) {
    return (
        <div className="mt-4">
            <ResponseExampleView
                items={route.responses}
                resources={resources}
                responses={responses}
            />
        </div>
    );
}
