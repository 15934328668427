import { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const ErrorText = ({ text }) => {
    if (text) {
        return (
            <div className="invalid-feedback" style={{ display: 'block' }}>
                {text}
            </div>
        );
    }
    return null;
};

const Input = ({
    type,
    id,
    name,
    className,
    labelClassName,
    placeHolder,
    rows,
    value,
    isDisabled,
    isRequired,
    labelText,
    errorText,
    resetPassIcon,
    onChange,
    onKeyPress,
}) => {
    const [error, setError] = useState(errorText || []);
    const [inputClass, setInputClass] = useState(className || '');
    const [labelClass, setLabelClass] = useState(labelClassName || '');

    const showError = useCallback(() => {
        setInputClass(`${className} is-invalid`);
        setLabelClass(`${labelClassName} is-invalid`);
    }, [className, labelClassName]);

    const clearError = useCallback(() => {
        if (resetPassIcon) {
            resetPassIcon();
        }
        setError('');
        setInputClass(`${className}`);
    }, [className, resetPassIcon]);

    useEffect(() => {
        if (errorText) {
            setError(errorText);
            showError();
        }
        if (!errorText) {
            clearError();
        }
    }, [clearError, errorText, showError]);

    return (
        <Fragment>
            {labelText && (
                <label className={labelClass} htmlFor={name}>
                    {labelText}
                    {isRequired && <span style={{ color: 'red' }}>*</span>}
                </label>
            )}
            {type === 'textarea' ? (
                <textarea
                    id={id}
                    name={name}
                    className={inputClass}
                    placeholder={placeHolder}
                    onChange={onChange}
                    onFocus={clearError}
                    onKeyPress={onKeyPress}
                    disabled={isDisabled}
                    rows={rows}
                    value={value}
                />
            ) : (
                <input
                    id={id}
                    name={name}
                    type={type}
                    className={inputClass}
                    placeholder={placeHolder}
                    value={value}
                    onChange={onChange}
                    onFocus={clearError}
                    onKeyPress={onKeyPress}
                    disabled={isDisabled}
                />
            )}
            {error && <ErrorText text={error[0]} />}
        </Fragment>
    );
};

Input.defaultProps = {
    type: 'text',
    labelText: '',
    value: '',
    name: '',
    className: '',
    labelClassName: '',
    rows: '5',
    placeHolder: '',
    inputStyle: {},
    isFullWidth: false,
    onChange: () => {},
    onKeyPress: () => {},
};

Input.propTypes = {
    /** Input tag type default is text. */
    type: PropTypes.string,
    /** Set value/Text to the input tag */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Input tag label display above the input tag. */
    labelText: PropTypes.string,
    /** Placeholder of the input tag */
    placeHolder: PropTypes.string,
    /** Input disabled or not */
    isDisabled: PropTypes.bool,
    /** Input value required or not */
    isRequired: PropTypes.bool,
    /** Used to set input width 100% */
    isFullWidth: PropTypes.bool,
    /** Function to call on input Change */
    onChange: PropTypes.func,
    /** Function to call on KeyPress */
    onKeyPress: PropTypes.func,
    /** Name of the input tag */
    name: PropTypes.string,
    /** Id of the input tag */
    id: PropTypes.string,
    /** Input element ClassName */
    className: PropTypes.string,
    /** Label element ClassName */
    rows: PropTypes.string,
    /** Label element ClassName */
    labelClassName: PropTypes.string,
    /** Input element styles */
    inputStyle: PropTypes.object,
    /** Error text */
    errorText: PropTypes.array,
    /* Function to call to reset 'eye' icon */
    resetPassIcon: PropTypes.func,
};

export default Input;
