import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../../utils/http';

export const getRoutes = createAsyncThunk(
    'pages/getRoutes',
    async ({ apiId, token, onError }, thunkAPI) => {
        try {
            const response = await http.get(`/api/v1/api/${apiId}/routes`, {
                headers: { 'Doc-Auth': token },
            });
            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const getRoute = createAsyncThunk(
    'pages/getRoute',
    async ({ apiId, routeId, token, onError }, thunkAPI) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/route/${routeId}`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const getRoutesGroups = createAsyncThunk(
    'pages/getRoutesGroups',
    async ({ apiId, token, onError }, thunkAPI) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/route-groups`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const getRouteGroup = createAsyncThunk(
    'pages/getRouteGroup',
    async ({ apiId, groupId, token, onError }, thunkAPI) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/route-group/${groupId}`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const getResources = createAsyncThunk(
    'pages/getResources',
    async ({ apiId, token, onError }, { rejectWithValue }) => {
        try {
            const response = await http.get(
                `/api/v1/cddocs/api/${apiId}/resources/models`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return rejectWithValue(error);
        }
    },
);

export const getResponses = createAsyncThunk(
    'pages/getResponses',
    async ({ apiId, token, onError }, { rejectWithValue }) => {
        try {
            const response = await http.get(
                `/api/v1/cddocs/api/${apiId}/resources/responses`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return rejectWithValue(error);
        }
    },
);

const initialState = {
    routes: [],
    resources: [],
    responses: [],
    route: {},
    routesGroups: [],
    routesGroup: {},
    isRoutesFetching: false,
    isRouteFetching: false,
    isResourcesFetching: false,
    resourcesLoadingStatus: 'idle',
    isResponsesFetching: false,
    responsesLoadingStatus: 'idle',
};

const slice = createSlice({
    name: 'route',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoutes.pending, (state) => {
                state.isRoutesFetching = true;
            })
            .addCase(getRoutes.fulfilled, (state, action) => {
                state.routes = action.payload;
                state.isRoutesFetching = false;
            })
            .addCase(getRoutes.rejected, (state) => {
                state.isRoutesFetching = false;
            })
            .addCase(getRoute.pending, (state) => {
                state.isRouteFetching = true;
            })
            .addCase(getRoute.fulfilled, (state, action) => {
                state.route = action.payload;
                state.isRouteFetching = false;
            })
            .addCase(getRoute.rejected, (state) => {
                state.isRouteFetching = false;
            })
            .addCase(getRoutesGroups.fulfilled, (state, action) => {
                state.routesGroups = action.payload;
            })
            .addCase(getRouteGroup.fulfilled, (state, action) => {
                state.routesGroup = action.payload;
            })
            .addCase(getResources.pending, (state, action) => {
                state.isResourcesFetching = true;
                state.resourcesLoadingStatus = 'loading';
            })
            .addCase(getResources.fulfilled, (state, action) => {
                state.resources = action.payload;
                state.isResourcesFetching = false;
                state.resourcesLoadingStatus = 'succeeded';
            })
            .addCase(getResources.rejected, (state) => {
                state.isResourcesFetching = false;
                state.resourcesLoadingStatus = 'rejected';
            })
            .addCase(getResponses.pending, (state, action) => {
                state.isResponsesFetching = true;
                state.responsesLoadingStatus = 'loading';
            })
            .addCase(getResponses.fulfilled, (state, action) => {
                state.responses = action.payload;
                state.isResponsesFetching = false;
                state.responsesLoadingStatus = 'succeeded';
            })
            .addCase(getResponses.rejected, (state) => {
                state.isResponsesFetching = false;
                state.responsesLoadingStatus = 'rejected';
            });
    },
});

export const { reducer: routeReducer, actions: routeAction } = slice;
