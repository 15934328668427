import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'react-toastify/dist/ReactToastify.css';

import { defineHost } from './utils/helpers';
import DocumentationPageLayout from './pages/Documentation/DocumentationPage/DocumentationPageLayout';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
    defineHost();

    return (
        <Provider store={store}>
            <BrowserRouter>
                <ErrorBoundary>
                    <Switch>
                        <Route path="/" component={DocumentationPageLayout} />
                    </Switch>
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
