import { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import RouteResponseTabs from '../RouteResponseTabs/RouteTabs';
import RequestHeadersView from './RequestHeadersView';
import PreBlock from '../PreBlock/PreBlock';
import JsonSchemaViewer from '../JsonSchemaViewer/JsonSchemaViewer';
import { useSelector } from 'react-redux';
import { routeSelectors } from '../../redux/selectors';
import { ChevronDown } from 'react-feather';
import { Collapse } from 'react-collapse';

const ResponseExampleView = ({ items, resources, responses }) => {
    const [activeTab, setActiveTab] = useState(
        items && items[0]?.http_status_code,
    );
    const [isOpened, setIsOpened] = useState(true);
    const route = useSelector(routeSelectors.getRoute);
    const handleClick = (value) => setActiveTab(value);

    const applyToResponseHeaders = route?.global_headers.filter(
        (el) => el.apply_to_response,
    );

    const allHeaders = [
        ...(route?.response_headers || []),
        ...applyToResponseHeaders,
    ];

    if (items && !!items.length) {
        return (
            <div>
                <RequestHeadersView items={allHeaders} />
                <div
                    onClick={() => setIsOpened(!isOpened)}
                    className="d-flex justify-content-between align-items-center ps-3 pe-2 cursor-pointer mt-3"
                    style={{
                        height: '47px',
                        borderBottom: '2px solid #edeff3',
                    }}
                >
                    <h6 className="text">Responses {`(${items.length})`}</h6>
                    <ChevronDown
                        size={16}
                        color="#001A37"
                        style={{ transform: isOpened && 'rotate(180deg)' }}
                    />
                </div>
                <Collapse isOpened={isOpened}>
                    <div className="pt-4">
                        <RouteResponseTabs
                            items={items}
                            activeTab={activeTab}
                            onClick={handleClick}
                        />
                        {items.map((item, index) => {
                            const httpStatusCode = item.http_status_code;
                            const foundSharedResponse = responses.find(
                                (el) => item.shared_response === el.id,
                            );

                            if (httpStatusCode !== activeTab) {
                                return null;
                            }

                            return (
                                <div key={index} className="response-example">
                                    {item.description && (
                                        <div className="response-example-desc ck ck-line-height word-break">
                                            <Markdown
                                                options={{
                                                    overrides: {
                                                        pre: PreBlock,
                                                    },
                                                }}
                                            >
                                                {item.description}
                                            </Markdown>
                                        </div>
                                    )}
                                    {foundSharedResponse ? (
                                        <div className="mb-4">
                                            <JsonSchemaViewer
                                                models={resources}
                                                schema={
                                                    foundSharedResponse.schema
                                                }
                                                schemaType="response"
                                            />
                                        </div>
                                    ) : (
                                        item?.schema && (
                                            <div className="mb-4">
                                                <JsonSchemaViewer
                                                    models={resources}
                                                    schema={
                                                        foundSharedResponse
                                                            ? foundSharedResponse.schema
                                                            : item?.schema
                                                    }
                                                    schemaType="response"
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </Collapse>
            </div>
        );
    } else {
        return <div className="mt-4">No responses added</div>;
    }
};

export default ResponseExampleView;
