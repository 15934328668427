import React, { useState, useEffect } from 'react';

const Tooltip = ({ id, content, hidden, children }) => {
    const [active, setActive] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        if (active) {
            const tooltipWrapper = document.getElementById(id);
            const topPosition = tooltipWrapper.getBoundingClientRect().top;
            const rightPosition = tooltipWrapper.getBoundingClientRect().right;

            setPosition({ top: topPosition, left: rightPosition });
        } else {
            setPosition({ top: 0, left: 0 });
        }
    }, [active, id]);

    const showTip = () => {
        setActive(true);
    };

    const hideTip = () => {
        setActive(false);
    };

    return (
        <div
            id={id}
            className="tooltip-wrapper"
            onMouseOver={showTip}
            onMouseOut={hideTip}
            onClick={hideTip}
        >
            {children}
            {active && (
                <div
                    className={`tooltip-tip  ${hidden && 'hidden'}`}
                    style={position}
                >
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
