import CDPlogo from '../../assets/img/logo2.svg';

const AppLogo = () => {
    return (
        <div className="app-logo">
            <a
                className="d-flex flex-column align-items-center text-black"
                href="https://cdprojects.com/"
            >
                <img
                    className="mb-3"
                    src={CDPlogo}
                    alt="logo"
                    width="40"
                    height="40"
                />
                <span>Powered by CDProjects</span>
            </a>
        </div>
    );
};

export default AppLogo;
