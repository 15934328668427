import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../../utils/http';

export const getPages = createAsyncThunk(
    'pages/getPages',
    async ({ apiId, token, onError }, thunkAPI) => {
        try {
            const response = await http.get(`/api/v1/api/${apiId}/pages`, {
                headers: { 'Doc-Auth': token },
            });
            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const getPage = createAsyncThunk(
    'pages/getPage',
    async ({ id, pageId, token }, thunkAPI) => {
        try {
            const response = await http.get(
                `/api/v1/api/${id}/page/${pageId}`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
);
const initialState = {
    pages: [],
    page: {},
    isPagesFetching: false,
    isPageFetching: false,
};
const slice = createSlice({
    name: 'page',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPages.pending, (state) => {
                state.isPagesFetching = true;
            })
            .addCase(getPages.fulfilled, (state, action) => {
                state.pages = action.payload;
                state.isPagesFetching = false;
            })
            .addCase(getPages.rejected, (state) => {
                state.isPagesFetching = false;
            })
            .addCase(getPage.pending, (state) => {
                state.isPageFetching = true;
            })
            .addCase(getPage.fulfilled, (state, action) => {
                state.page = action.payload;
                state.isPageFetching = false;
            })
            .addCase(getPage.rejected, (state) => {
                state.isPageFetching = false;
            });
    },
});

export const { reducer: pageReducer, actions: pageAction } = slice;
