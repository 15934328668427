//Route selectors
export const routeSelectors = {
    getRoute: (state) => state.route.route,
    getRoutes: (state) => state.route.routes,
    getResources: (state) => state.route.resources,
    getResponses: (state) => state.route.responses,
    getRoutesTrash: (state) => state.route.routesTrash,
    getIsRoutesFetching: (state) => state.route.isRoutesFetching,
    getIsRouteFetching: (state) => state.route.isRouteFetching,
    getResourcesLoadingStatus: (state) => state.route.resourcesLoadingStatus,
    getRoutesGroups: (state) => state.route.routesGroups,
};

//Page selectors
export const pageSelectors = {
    getPage: (state) => state.page.page,
    getPages: (state) => state.page.pages,
    getIsPagesFetching: (state) => state.page.isPagesFetching,
    getIsPageFetching: (state) => state.page.isPageFetching,
};

//Documentation selector

export const documentationSelectors = {
    getApi: (state) => state.documentation.api,
    getEnvironments: (state) => state.documentation.environments,
    getSelectedEnvironment: (state) => state.documentation.selectedEnvironment,
    getIsApiInformationFetching: (state) =>
        state.documentation.isApiInformationFetching,
    getShowGlobalLoader: (state) => state.documentation.showGlobalLoader,
};
