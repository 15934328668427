import React, { useState, useEffect } from 'react';

import folderOpen from '../../../assets/img/folder-open.svg';
import folderClose from '../../../assets/img/folder-close.svg';
import RoutesItem from './RoutesItem';

const RoutesGroup = ({ group, children }) => {
    const [isFolderOpened, setIsFolderOpened] = useState(false);

    const handleToggleFolder = () => {
        setIsFolderOpened((prev) => !prev);
    };

    useEffect(() => {
        const currentRouteId = +window.location.pathname.split('/')[2];
        const isFoundRoute = children.find(
            (route) => route.id === currentRouteId,
        );

        if (isFoundRoute) {
            setIsFolderOpened(true);
        }
    }, [children]);

    return (
        <ul className="nav flex-column">
            <li className="nav-item sidebar-link-item mx-2">
                <div
                    onClick={handleToggleFolder}
                    className="nav-link sidebar-link sidebar-link-route wrod-break"
                >
                    {isFolderOpened ? (
                        <img
                            src={folderOpen}
                            alt="folder-open"
                            style={{ marginRight: '4px' }}
                        />
                    ) : (
                        <img
                            src={folderClose}
                            alt="folder-close"
                            style={{ marginRight: '4px' }}
                        />
                    )}
                    <span className="me-1">{group.name}</span>
                </div>
            </li>
            {!!children.length && (
                <ul
                    className={`nav flex-column nav-children ${
                        !isFolderOpened && 'd-none'
                    }`}
                >
                    {children.map((route) => {
                        return <RoutesItem key={route.id} route={route} />;
                    })}
                </ul>
            )}
        </ul>
    );
};

export default RoutesGroup;
