const GlobalLoader = ({ isLoading, bgColor }) => {
    const DivStyle = {
        position: 'fixed',
        zIndex: '20000000',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: bgColor || '#ccd3db',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            {isLoading ? (
                <div style={DivStyle}>
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border spinner-border-md text-primary"
                            role="status"
                        ></div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default GlobalLoader;
