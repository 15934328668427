import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { capitalize } from './helpers';
import ViewerList from './ViewerList';
import { ChevronRight } from 'react-feather';
import RequiredMark from '../RequiredMark/RequiredMark';
import { SchemaTypeContext } from './JsonSchemaViewer';

const typesColors = {
    string: '#00c26e',
    number: '#de4a4a',
    integer: '#f36f2d',
    boolean: '#9061f9',
    object: '#126aec',
    array: '#00b75f',
    $ref: '#6e2fff',
    null: '#b4842f',
    enum: '#ff2d68',
    allOf: '#87bb23',
    anyOf: '#87bb23',
    oneOf: '#87bb23',
};

export default function ViewerListItem({
    listItem,
    isCollapsed,
    setCollapsed,
    models,
}) {
    const {
        name,
        detached,
        type,
        subType,
        children,
        id,
        parent,
        refName,
        refPath,
        metadata,
        isRequired,
        isNullable,
        enumValue,
        description,
        extraProps,
        isCombinerChild,
    } = listItem;
    const [rowHeight, setRowHeight] = useState(36);
    const box = useRef();

    useLayoutEffect(() => {
        if (box?.current?.offsetHeight)
            setRowHeight(box?.current?.offsetHeight);
    }, []);

    const getType = (type, subType) => {
        const thisType = Array.isArray(type) ? type[0] : type;
        const thisSubType =
            subType && Array.isArray(subType) ? subType[0] : subType;

        if (!thisSubType) return thisType;

        return `${thisType}[${thisSubType}]`;
    };

    const setupType = () => {
        let resultType = resolvedType;
        if (enumValue) resultType = 'enum';

        if (resolvedType === 'array[$ref]') {
            resultType = `array[${connectedRef || '$ref'}]`;
        }

        if (resolvedType === '$ref') {
            resultType = connectedRef;
        }

        return isSchemaRoot || isDetachedTreeRoot
            ? capitalize(resultType)
            : resultType;
    };

    const resolvedType = getType(type, subType);
    const schemaType = useContext(SchemaTypeContext);
    const isNotPrimitiveType = [
        'object',
        'array[object]',
        '$ref',
        'array[$ref]',
        'oneOf',
        'allOf',
        'anyOf',
    ].includes(resolvedType);
    const isExpanded = !isCollapsed.includes(id);
    const behaviourMap = { write: 'write-only', read: 'read-only' };
    const isSchemaRoot = !parent;
    const isDetachedTreeRoot = detached && name === 'root';
    const connectedRef = metadata.refSchema ? refName : refPath;
    const typeColor = resolvedType.includes('array')
        ? typesColors.array
        : typesColors[enumValue ? 'enum' : resolvedType];
    const displayedType = setupType();
    const behaviourStyles = {
        marginLeft: listItem.behaviour === 'read' ? '30px' : '29px',
    };

    return (
        <>
            <div ref={box} className="JsonSchemaViewer__schema-row">
                <div className="JsonSchemaViewer__schema-row-inner-wrapper">
                    <div
                        className="JsonSchemaViewer__schema-row-aligned"
                        style={{
                            paddingLeft: `${listItem.depth * 20}px`,
                        }}
                    >
                        <div className="JsonSchemaViewer__schema-row-entries-wrapper">
                            <div
                                className="JsonSchemaViewer__schema-row-entries"
                                style={{
                                    alignSelf:
                                        rowHeight > 36
                                            ? 'flex-start'
                                            : 'center',
                                }}
                            >
                                {isNotPrimitiveType && !!children ? (
                                    <button
                                        className="JsonSchemaViewer__schema-static-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setCollapsed(id);
                                        }}
                                        type="button"
                                        style={{
                                            transform:
                                                isExpanded && 'rotate(90deg)',
                                        }}
                                    >
                                        <ChevronRight size={16} />
                                    </button>
                                ) : (
                                    <div
                                        style={{
                                            minWidth: '28px',
                                            height: '28px',
                                            width: '28px',
                                        }}
                                    ></div>
                                )}
                                {isSchemaRoot ||
                                isDetachedTreeRoot ||
                                isCombinerChild ? (
                                    <>
                                        <span
                                            className="ms-2"
                                            style={{
                                                ...(extraProps?.deprecated && {
                                                    textDecoration:
                                                        'line-through',
                                                }),
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: typeColor,
                                                }}
                                            >
                                                {displayedType}
                                                {extraProps?.format &&
                                                    `<${extraProps?.format}>`}
                                            </span>
                                        </span>
                                        {enumValue && (
                                            <div className="ms-2">
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {'[ '}
                                                </span>
                                                {enumValue?.map((el, i) => (
                                                    <span
                                                        key={i}
                                                        className="JsonSchemaEditor__schema-row-children-counter m-0"
                                                    >
                                                        {`${
                                                            i > 0 ? ' , ' : ''
                                                        }${el}`}
                                                    </span>
                                                ))}
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {' ]'}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="ms-2 d-flex align-items-center">
                                        <span
                                            className="position-relative"
                                            style={{
                                                maxWidth: '250px',
                                                ...(extraProps?.deprecated && {
                                                    textDecoration:
                                                        'line-through',
                                                }),
                                            }}
                                        >
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                        </span>
                                        {isRequired && <RequiredMark />}
                                        <span className="mx-1 pe-none">:</span>
                                        <span
                                            style={{
                                                ...(extraProps?.deprecated && {
                                                    textDecoration:
                                                        'line-through',
                                                }),
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: typeColor,
                                                }}
                                            >
                                                {displayedType}
                                                {extraProps?.format &&
                                                    `<${extraProps?.format}>`}
                                            </span>

                                            {isNullable &&
                                                displayedType !== 'null' && (
                                                    <>
                                                        <span className="mx-1">
                                                            or
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: typesColors.null,
                                                            }}
                                                        >
                                                            null
                                                        </span>
                                                    </>
                                                )}
                                        </span>
                                        {enumValue && !!enumValue.length && (
                                            <div className="ms-2">
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {'[ '}
                                                </span>
                                                {enumValue?.map((el, i) => (
                                                    <span
                                                        key={i}
                                                        className="JsonSchemaViewer__schema-row-aditional-info"
                                                    >
                                                        {`${
                                                            i > 0 ? ' , ' : ''
                                                        }${el}`}
                                                    </span>
                                                ))}
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {' ]'}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {description && (
                                <div className="JsonSchemaViewer__schema-row-description">
                                    {description}
                                </div>
                            )}
                        </div>
                        {schemaType === 'model' &&
                            (listItem.behaviour === 'read' ||
                                listItem.behaviour === 'write') && (
                                <div
                                    style={{
                                        ...(description && behaviourStyles),
                                        alignSelf:
                                            rowHeight > 36
                                                ? 'flex-start'
                                                : 'center',
                                    }}
                                >
                                    <span className="JsonSchemaViewer__schema-row-behaviour">
                                        {behaviourMap[listItem.behaviour]}
                                    </span>
                                </div>
                            )}
                        {description && listItem.behaviour === 'read/write' && (
                            <div>
                                <div className="JsonSchemaViewer__schema-row-behaviour-mock"></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {children && isExpanded && (
                <ViewerList
                    list={children}
                    isCollapsed={isCollapsed}
                    models={models}
                    setCollapsed={setCollapsed}
                />
            )}
        </>
    );
}
