import React, {
    useEffect,
    createContext,
    useCallback,
    useState,
    useMemo,
} from 'react';
import { defineSchema, validateSchema } from './helpers';
import './assets/styles/styles.scss';
import RequiredMark from '../RequiredMark/RequiredMark';
import ViewerTabsFacade from './ViewerTabsFacade';
import usePrevious from './hooks/usePrevious';
import { Builder } from './builder/builder';

export const SchemaTypeContext = createContext(null);

export default function JsonSchemaViewer({
    modelName,
    schema,
    models,
    schemaType = 'default',
}) {
    const build = useMemo(
        () =>
            new Builder(defineSchema(schema, modelName), null, {
                models,
            }),
        [modelName, models, schema],
    );
    const [isCollapsed, setIsCollapsed] = useState([]);
    const [data, setData] = useState([]);
    const [tree, setTree] = useState([]);
    const [activeTab, setActiveTab] = useState('schema');
    const [examples, setExamples] = useState([]);
    const prevSchema = usePrevious(schema);

    const selectActiveTab = (e) => {
        const { dataset } = e.currentTarget;

        setActiveTab(dataset.key);
    };

    function getSchemaExamples(schema) {
        if (!schema) return {};
        return Object.values(schema)[0]?.['x-examples'] || {};
    }

    const filterByBehaviour = useCallback(
        (data) => {
            const typesMap = {
                request: () => data.filter((el) => el.behaviour !== 'read'),
                response: () => data.filter((el) => el.behaviour !== 'write'),
            };
            return typesMap?.[schemaType]?.() || data;
        },
        [schemaType],
    );

    useEffect(() => {
        if (schema !== prevSchema) {
            build.build();
            setIsCollapsed(Array.from(build.collapsed));
            setData(build.dataArray);
            setExamples(getSchemaExamples(validateSchema(schema)));
        }
        const filteredData = filterByBehaviour(data);
        const tree = build.buildTree(filteredData);

        setTree(tree);
    }, [build, data, filterByBehaviour, prevSchema, schema]);

    const setCollapsed = useCallback(
        (id) => {
            if (isCollapsed.includes(id)) {
                const result = isCollapsed.filter((item) => item !== id);
                setIsCollapsed(result);
                return;
            }
            setIsCollapsed([...isCollapsed, id]);
        },
        [isCollapsed],
    );

    const onCollapseAll = () => {
        const collapsableElements = data
            .filter((el) => el.metadata.children || el.type === '$ref')
            .map((el) => el.id);
        setIsCollapsed(collapsableElements);
    };

    const onExpandAll = () => {
        setIsCollapsed([]);
    };

    const hasExamples = Object.keys(examples)?.length;
    const tabsProps = {
        activeTab,
        tree,
        isCollapsed,
        models,
        data,
        examples,
        setCollapsed,
    };

    return (
        <SchemaTypeContext.Provider value={schemaType}>
            <div className="JsonSchemaViewer__outer-wrapper">
                <div className="JsonSchemaViewer__navigation-pannel">
                    <>
                        <div className="d-flex justify-content-between">
                            <div>
                                <button
                                    className={
                                        activeTab === 'schema'
                                            ? 'JsonSchemaViewer__tab-button JsonSchemaViewer__tab-button--active me-2'
                                            : 'JsonSchemaViewer__tab-button me-2'
                                    }
                                    onClick={selectActiveTab}
                                    data-key="schema"
                                    type="button"
                                >
                                    Schema
                                </button>
                                <button
                                    className={
                                        activeTab === 'json'
                                            ? 'JsonSchemaViewer__tab-button JsonSchemaViewer__tab-button--active me-2'
                                            : 'JsonSchemaViewer__tab-button me-2'
                                    }
                                    onClick={selectActiveTab}
                                    data-key="json"
                                    type="button"
                                >
                                    JSON
                                </button>
                                {!!hasExamples && (
                                    <button
                                        className={
                                            activeTab === 'examples'
                                                ? 'JsonSchemaViewer__tab-button JsonSchemaViewer__tab-button--active me-2'
                                                : 'JsonSchemaViewer__tab-button me-2'
                                        }
                                        onClick={selectActiveTab}
                                        data-key="examples"
                                        type="button"
                                    >
                                        Examples
                                    </button>
                                )}
                            </div>
                            <div className="d-flex">
                                <div className="me-2">
                                    <button
                                        className="JsonSchemaViewer__tab-button me-2"
                                        onClick={onCollapseAll}
                                        type="button"
                                    >
                                        Collapse all
                                    </button>
                                    <button
                                        className="JsonSchemaViewer__tab-button"
                                        onClick={onExpandAll}
                                        type="button"
                                    >
                                        Expand all
                                    </button>
                                </div>
                                {schemaType !== 'response' && (
                                    <div className="text-end pe-3 mt-1">
                                        <span
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 500,
                                            }}
                                        >
                                            <RequiredMark /> - required field
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                </div>
                <ViewerTabsFacade {...tabsProps} />
            </div>
        </SchemaTypeContext.Provider>
    );
}
